import rg4js from 'raygun4js';
import {environment} from "@ametektci/ametek.stcappscommon";
//UpdatesSTore still needs the UpdatesURL here. 
const _envSettings :{[key:string]: environment}= {
    'local' : {
        'CcwApiUrl' : 'https://localhost:5002',
        'rayGunApiKey' : '',
    },
    'dev' : {
        'CcwApiUrl' : 'https://dev-api.crystalcontrolweb.com',
        'rayGunApiKey' : 'wVec8kKpobonG1gS0r8lKQ',
    },
    'staging' : {
        'CcwApiUrl' : 'https://staging-api.crystalcontrolweb.com',
        'rayGunApiKey' : 'wVec8kKpobonG1gS0r8lKQ',
    },
    'production' : {
        'CcwApiUrl' : 'https://api.crystalcontrolweb.com',
        'rayGunApiKey' : 'wVec8kKpobonG1gS0r8lKQ',
    }
};
type envs = keyof typeof _envSettings
type environments = typeof _envSettings[envs]


export const GetEnvironmentSetting = function(key: string) {
    // determine the env
    let env = GetEnvironment();
    // get the appropriate value for the env
    let value = _envSettings[env][key as keyof environments];
    // if we couldn't find it, alert ray gun, something is missing
    if ( value === undefined) {
        console.error('unknown environment variable', key);
        rg4js('send', new Error(`Unknown environment variable for env ${env}. KEY = ${key}. (running at location : ${window.location.href})`))
    }

    return value;
};

/**
 * @return {string}
 */
export const GetEnvironment = function() : envs {
    if (window.location.hostname.includes('localhost'))
        return 'local';
    else if (window.location.hostname.includes('dev'))
        return 'dev';
    else if (window.location.hostname.includes('staging'))
        return 'staging';
    return 'production';
};

export const ExportEnvironmentSettings = function() {
    let env = GetEnvironment();
    return _envSettings[env];
};
